/* HomePage.css */
.main-content {
    padding: 20px;
  }
  
  .header {
    text-align: center;
    padding: 20px 0;
  }
  
  .header h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .hero-section {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .hero-image {
    flex: 1;
    max-width: 50%;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
  
  .hero-text {
    flex: 1;
    padding: 0 20px;
  }
  
  .hero-text h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .hero-text p {
    margin-bottom: 20px;
  }
  
  .btn {
    display: inline-block;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .btn:hover {
    background-color: #555;
  }
  
  .features-section {
    display: flex;
    justify-content: space-between;
  }
  
  .feature {
    flex: 1;
    text-align: center;
    margin: 0 10px;
  }
  
  .feature img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 0.5rem;
  }
  
  .feature h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .feature p {
    margin-bottom: 20px;
  }
  
  