/* Page.css */
.page {
    padding: 40px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .text {
    flex: 1;
    padding: 0 20px;
  }
  
  .text p {
    margin-bottom: 20px;
  }
  